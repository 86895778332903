import api from '@/services/api'

export default {
  getAll(page, term, company, cart) {
    return api.get(`/products?page=${page}&term=${term}&company=${company}&cart=${cart}`)
  },

  getPrices(page, company, customer) {
    return api.get(`/products/prices?page=${page}&companyId=${company}&customerId=${customer}`)
  },

  getStock(page, term = '', company) {
    return api.get(`/products/stock?page=${page}&term=${term}&company=${company}`)
  },

  exportPrices(company, customer) {
    return api({
      url: `/products/prices?export=true&companyId=${company}&customerId=${customer}`,
      method: 'GET',
      responseType: 'blob',
    })
  },

  exportStock(term, company) {
    return api({
      url: `/products/stock?export=true&term=${term}&company=${company}`,
      method: 'GET',
      responseType: 'blob',
    })
  },

  uploadBanner(formData) {
    return api.post('/banners', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  uploadWebBanner(formData) {
    return api.post('/banners/web', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  getBanners() {
    return api.get('/banners/')
  },

  getBannersWeb() {
    return api.get('/banners?web=true')
  },

  removeBanner(id) {
    return api.delete(`/banners/${id}`)
  },

  updateBanner(data) {
    return api.put(`/banners/${data._id}`, data)
  },

  getDepartments() {
    return api.get('/products/b2b/departments')
  },

  getUniqueProducts(term = '') {
    return api.get(`/products/b2b/unique?term=${term}`)
  },

  addProductImage(formData, code) {
    return api.post(`/products/b2b/images/${code}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  updateImages(code, images) {
    return api.put(`/products/b2b/images/${code}`, { images })
  },
}